<template>
	<div class="login-form rythm-v">
		<header>
			<h2>Connexion</h2>
		</header>

		<!-- Signin form -->
		<div class="form-wrapper"
				 v-if="!preAuthCheck?.auth">

			<form method="post"
						@submit.prevent="authUserTotpCheck">
				<div class="input-group required">
					<label for="login">Adresse e-mail</label>
					<input type="email"
								 name="login"
								 id="login"
								 v-model="loginData.login"
								 required />
				</div>

				<div class="flex-item--50 input-group required">
					<label for="password">Mot de passe</label>
					<input type="password"
								 name="password"
								 id="password"
								 v-model="loginData.password"
								 required />
				</div>

				<div class="input-group--inline-controls">
					<button type="submit"
									class="button--primary"
									:disabled="!turnstile.success || isLoading">
						<svg class="icon"
								 role="img"
								 xmlns="http://www.w3.org/2000/svg"
								 width="18"
								 height="18"
								 fill="none"
								 viewBox="0 0 18 18">
              <path
                fill="currentColor"
                d="M10.8.9a.9.9 0 0 1 .9-.9h3.6A2.7 2.7 0 0 1 18 2.7v12.6a2.7 2.7 0 0 1-2.7 2.7h-3.6a.9.9 0 1 1 0-1.8h3.6a.9.9 0 0 0 .9-.9V2.7a.9.9 0 0 0-.9-.9h-3.6a.9.9 0 0 1-.9-.9z"
              />
              <path
                fill="currentColor"
                d="M6.564 3.864a.9.9 0 0 1 1.272 0l4.5 4.499.006.007a.897.897 0 0 1 .258.627v.006a.898.898 0 0 1-.258.627l-.006.007-4.5 4.5a.9.9 0 1 1-1.272-1.273L9.527 9.9H.9a.9.9 0 1 1 0-1.8h8.627L6.564 5.136a.9.9 0 0 1 0-1.272z"
              /></svg><span class="caption">Connexion</span>
					</button>

					<p>
						<router-link to="/password-reset">Mot de passe oublié&nbsp;?</router-link>
					</p>
				</div>
			</form>

			<!-- Captcha -->
			<div class="flex-row flex-center">
				<cfturnstile :sitekey="turnstile.sitekey"
										 @verify="verifyCaptcha" />
			</div>
		</div>

		<!-- TOTP form -->
		<div class="form-wrapper rythm-v"
				 v-if="preAuthCheck?.auth">

			<p v-if="preAuthCheck?.totp_mode === 'email' && preAuthCheck?.totp_sent"><strong>Un code 2FA vous a été envoyé par e-mail.</strong></p>
			<p v-if="preAuthCheck?.totp_mode === 'app'"><strong>Entrez le code de votre application 2FA.</strong></p>

			<form @submit.prevent="signinUser"
						class="rythm-v">

				<div class="totp-check-input">

					<div class="input-group w-100">
						<label class="flex-noshrink"
									 for="totpCode"><strong>Code 2FA&nbsp;:</strong></label>
						<input type="text"
									 class="text-center totp-code-input"
									 name="totpCode"
									 id="totpCode"
									 autocomplete="one-time-code"
									 inputmode="numeric"
									 maxlength="6"
									 pattern="\d{6}"
									 placeholder="******"
									 v-model="loginData.totp"
									 required />
					</div>

					<div class="input-group--controls flex-row flex-center w-100">
						<button type="button"
										class="button--outline flex-grow"
										@click="sendTotpCode"
										:disabled="isLoading"
										v-if="preAuthCheck?.totp_mode === 'app'">
							<IconMailPlus></IconMailPlus>
							<span class="caption">Recevoir le code par e-mail</span>
						</button>

						<a v-else
							 href="/"
							 class="button--outline">Recommencer</a>

						<button type="submit"
										class="button--primary flex-grow"
										:disabled="!loginData.totp || isLoading">
							<IconFingerprint></IconFingerprint>
							<span class="caption">Vérifier le code</span>
						</button>
					</div>

				</div>
			</form>

		</div>


	</div>
</template>

<script>
import { appConfig } from "../config.js";
import { EventBus } from "../event-bus.js";
import Turnstile from 'cfturnstile-vue2'
import IconFingerprint from '../assets/svg/icons/icon-fingerprint.svg';
import IconMailPlus from '../assets/svg/icons/icon-mail-plus.svg';

export default {
	name: "loginForm",

	components: {
		'cfturnstile': Turnstile,
		IconFingerprint,
		IconMailPlus
	},

	data: function () {
		return {
			loginData: {
				password: "",
				login: "",
				totp: ""
			},

			turnstile: {
				sitekey: `${appConfig.TURNSTILE_SITE_KEY}`,
				token: null,
				success: false
			},

			preAuthCheck: {
				auth: false
			},

			isLoading: false
		};
	},

	methods: {

		// #1 - Pre-auth: check TOTP mode and send code if "email"
		authUserTotpCheck: async function () {
			this.isLoading = true;
			this.$store
				.dispatch("AUTH_TOTP_CHECK", this.loginData)
				.then((response) => {
					this.isLoading = false;
					this.$toasted.clear();
					this.preAuthCheck = { ...response };
					if (response?.totp_mode === 'email' && !response?.totp_sent) {
						this.$toasted.global.appError({ message: "Impossible d’envoyer le code par e-mail, veuillez réessayer." });
					}

				})
				.catch((error) => {
					this.isLoading = false;
					this.preAuthCheck.auth = false;
					console.log("authUserTotpCheck", error);
					let message = "<strong>Connexion impossible</strong>, vérifiez votre identifiant et votre mot de passe !";

					if (error?.status === 449) {
						message = '<strong>Connexion impossible</strong>, activez l’authentification à deux facteurs sur la page "mot de passe oublié".';
					}

					if (error?.status === 403 && error?.data?.payload?.error === 'password-expired') {
						message = '<strong>Connexion impossible : votre mot de passe a expiré.</strong> Veuillez le modifier sur la page <em>"Mot de passe oublié ?"</em>';
					}

					// Le compte n'est pas activé (email opt-in)
					this.$toasted.global.appError({
						message: message
					});
				});
		},

		// #2 - Authentification de l'utilisateur (demande de token)
		signinUser: async function () {
			this.isLoading = true;
			this.$store
				.dispatch("AUTH_REQUEST", this.loginData)
				.then(() => {
					this.isLoading = false;
					EventBus.$emit("trackEvent", "Authentification", "Authentification réussie");

					this.$toasted.clear();

					// NOTE: Authorization Cookie: les tokens sont envoyés par cookies
					// - Si l'authentification est réussie, l'API délivre un cookie http-only + secure (sur le domaine de l'API)
					// - Les cookies sont renvoyés automatiquement à chaque requête.

					// Authorization Header (JWT)
					// (Si un token est déjà enregistré en localStorage, il est passé à chaque requête.)
					// -> L'authentification (AUTH_REQUEST) s'est déroulée avec succès : on ajoute le token à toutes les requêtes :
					// HTTP.defaults.headers.common.Authorization = "Bearer " + this.$store.state.userToken;

					// Maintenant on connecte l'utilisateur
					this.loginUser();
				})
				.catch((error) => {
					this.isLoading = false;
					EventBus.$emit("trackEvent", "Authentification", "Erreur d’authentification");
					let message = "<strong>Connexion impossible</strong>, vérifiez votre identifiant et votre mot de passe !";

					if (error?.status === 449) {
						message = '<strong>Connexion impossible</strong>, activez l’authentification à deux facteurs sur la page "mot de passe oublié"';
					}

					if (error?.status === 403 && error?.data?.payload?.error === 'invalid-totp-code') {
						message = '<strong>Connexion impossible</strong>, le code d’authentification à deux facteurs est invalide.';
					}

					if (error?.status === 403 && error?.data?.payload?.error === 'password-expired') {
						message = '<strong>Connexion impossible : votre mot de passe a expiré.</strong> Veuillez le modifier sur la page <em>"Mot de passe oublié ?"</em>';
					}

					// Le compte n'est pas activé (email opt-in)
					this.$toasted.global.appError({
						message: message
					});
				});
		},

		// #3 - Connexion de l'utilisateur authentifié (récupération des infos de l'utilisateur grace au token reçu)
		loginUser: async function () {
			this.isLoading = true;
			this.$store
				.dispatch("USER_LOGIN")
				.then((response) => {
					this.isLoading = false;
					EventBus.$emit("trackEvent", "Connexion", `Utilisateur ${response.data.payload.id} connecté`);
					console.log("loginUser, startHeartBeat?", response);

					// Initiate the accessToken refresh hearbeat
					this.startHeartBeat();

					// Redirect to the dashboard
					// this.$router.push("/cases");
				})
				.catch((err) => {
					this.isLoading = false;

					if (!err) {
						// EventBus.$emit("trackEvent", "Connexion", "Échec de connexion : erreur inconnue");
						return console.warn("err unedfined?");
					}

					// Le compte n'est pas vérifié (email opt-in)
					if (err.status === 403) {
						EventBus.$emit("trackEvent", "Connexion", "Échec de connexion : compte non vérifié");
						return this.$toasted.global.appError({
							message: "Votre compte n’est pas vérifié, <strong>consultez votre boite mail.</strong>",
						});
					}

					// Erreur d'authentification
					else {
						EventBus.$emit("trackEvent", "Connexion", "Échec de connexion : identifiants incorrects");
						return this.$toasted.global.appError({
							message: "Impossible de vous connecter,<br><strong>vérifiez votre identifiant et votre mot de passe !</strong>",
						});
					}
				});
		},

		// Send TOTP Code by email
		sendTotpCode: async function () {
			this.$toasted.clear();

			let token = this.preAuthCheck?.token || "";
			if (!token) return;

			const payload = {
				token: token
			};

			this.isLoading = true;

			await this.$store
				.dispatch("SEND_TOTP_CODE", payload)
				.then((data) => {
					this.isLoading = false;
					this.$toasted.global.appSuccess({
						message: "<strong>Code envoyé !</strong> Consultez votre boîte de réception.",
					});
				})
				.catch((error) => {
					this.isLoading = false;
					let message = "Une erreur est survenue";

					if (error.status === 403) {
						message = "Impossible d’envoyer le code 2FA, veuillez recommencer.";
					}

					if (error.status === 429) {
						message = "Veuillez patienter quelques instants pour demander un nouveau code.";
					}

					this.$toasted.global.appError({ message: message });
				});

			setTimeout(() => {
				this.$toasted.clear();
			}, 3000)
		},

		verifyCaptcha: function (token) {
			const payload = {
				challenge: token,
			};

			this.$store
				.dispatch("VERIFY_CAPTCHA", payload)
				.then((response) => {
					if (response.success) this.turnstile.success = true;
					else {
						this.turnstile.success = false;
						this.$toasted.global.appError({
							message: "<strong>Connexion impossible</strong>, erreur de vérification du CAPTCHA."
						});
					}
				})
				.catch((error) => {
					this.turnstile.success = false;
					let message = "<strong>Connexion impossible</strong>, erreur de vérification du CAPTCHA !";

					// CAPTCHA verification error
					this.$toasted.global.appError({
						message: message
					});
				});
		},
	},
};
</script>


<style lang="scss" scoped>
@import "~@/scss/config";
@import "~@/scss/06-components/components.form";

.input-group--inline-controls {
	align-items: center;
	justify-content: center;
}

form>*+* {
	margin-top: 1rem;
}


.totp-check-input {
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	flex-basis: 100%;

	.input-group--controls {
		flex-basis: 100%;
		gap: 1rem;
		width: 100%;
	}

	label[for="totpCode"] {
		display: none;
	}

	button {
		justify-content: center;
		align-items: center;
	}
}
</style>